"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = __importStar(require("react"));
var thunks_1 = require("../services/QueryToken/thunks");
var hook_1 = require("../store/hook");
function Logout() {
    var dispatch = (0, hook_1.useAppDispatch)();
    (0, react_1.useEffect)(function () {
        var timer = setTimeout(function () {
            (0, thunks_1.logout)(dispatch);
            window.location.href = 'https://www.declarations-juridiques.fr/tableau-de-bord';
        }, 2500);
        return function () { return clearTimeout(timer); };
    }, []);
    return (react_1.default.createElement("div", { className: "flex items-center justify-center px-4 sm:px-6 lg:px-8" },
        react_1.default.createElement("div", { className: "max-w-2xl w-full space-y-8 bg-white p-12 rounded-xl shadow-2xl" },
            react_1.default.createElement("div", { className: "grid place-content-center" },
                react_1.default.createElement("img", { alt: "RGPD Copro Logo", src: require('../../img/logo.png'), className: "w-96 mx-auto" }),
                react_1.default.createElement("div", { className: "mt-4 text-right" },
                    react_1.default.createElement("h2", { className: "inline-block" }, "by"),
                    react_1.default.createElement("img", { alt: "D\u00E9clarations Juridiques Logo", src: require('../../img/logoDJ.svg'), className: "w-24 inline-block ml-2 align-middle" }))),
            react_1.default.createElement("div", { className: "text-center" },
                react_1.default.createElement("h2", { className: "mt-6 text-4xl font-extrabold text-gray-900" }, "D\u00E9connexion"),
                react_1.default.createElement("p", { className: "mt-2 text-lg text-gray-600" }, "Vous avez \u00E9t\u00E9 d\u00E9connect\u00E9 avec succ\u00E8s !")),
            react_1.default.createElement("div", { className: "mt-10 space-y-8" },
                react_1.default.createElement("div", { className: "rounded-md shadow-sm -space-y-px" },
                    react_1.default.createElement("div", { className: "px-6 py-6 bg-gray-50 sm:p-8" },
                        react_1.default.createElement("div", { className: "max-w-xl text-base text-gray-500" },
                            react_1.default.createElement("p", null,
                                "Redirection vers",
                                ' ',
                                react_1.default.createElement("a", { href: "https://www.declarations-juridiques.fr/tableau-de-bord", className: "italic underline" }, "D\u00E9clarations Juridiques"),
                                ' ',
                                "dans quelques secondes..."))))))));
}
exports.default = Logout;
